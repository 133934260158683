import axios from "axios";
import { getToken } from "../helpers/localStorage";
import { Link, useHistory } from "react-router-dom";
import { REACT_NETWORK_PROVIDER } from "../constants";
import CryptoJS from 'crypto-js';

// const apiUrl = process.env.REACT_APP_API_URL;

const apiUrl = "http://78.110.161.244:8082/api";
const api_url = "http://78.110.161.244:8082/api";

const networkProvider = process.env.REACT_APP_NETWORK_PROVIDER;
const keyHex = process.env.REACT_APP_KEY_HEX;
const ivHex = process.env.REACT_APP_IV_HEX;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "Bearer " + getToken(),
};

const instance = axios.create({
  baseURL: apiUrl,
  timeout: 500000,
  headers,
});
function redirectToLogin() {
  let publicUrl = process.env.PUBLIC_URL;
  let loginPageUrl = null;
  loginPageUrl = publicUrl + "/";

  let origin = window.location.origin;
  if (window.location.origin === origin + loginPageUrl) return;
  window.location.href = loginPageUrl;
}

instance.interceptors.response.use(
  function (response) {
    let finalResponse = { ...response, status: 200, statusCode: 200 };
    return finalResponse;
  },
  function (error) {
    if (error?.response?.status === 401) {
      return redirectToLogin();
    } else {
      let finalResponse = {
        ...error,
        status: error?.response?.status,
        statusCode: error?.response?.status,
      };
      return finalResponse;
    }
  }
);





function encryptJson(jsonObject) {
    const plaintext = JSON.stringify(jsonObject); // Convert JSON object to string
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

function decryptJson(ciphertext_base64) {
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const decrypted = CryptoJS.AES.decrypt(ciphertext_base64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedText); // Convert string back to JSON object
}



function processEncryResult(response) {
  let { statusCode } = response;

  let xx = decryptJson(response.data.data)


  try {
    if (statusCode === 200) {
      return {
        success: response.data.status,
        data: decryptJson(response.data.data),
        message: response?.data?.message|| "Someting went wrong, please try again...",
        status_code: response?.data?.status_code || 200,
        otp_sent: response?.data?.otp_sent || false,
        // status:response?.data?.status
      };
    } else if (statusCode === 500) {
      return { success: false, message: "Someting went wrong" };
    }
    return {
      success: response.response.data.status,
      message: response?.response?.data?.message|| "Someting went wrong, check your internet...",
    };
  } catch (error) {
    console.log(error)


    return {

      error: error?.response?.response?.data?.status || "Someting went wrong, check your internet connection.",
      message: error?.response?.response?.data?.message || "Someting went wrong, check your internet connection..",
    };
  }
}

function processResult(response) {
  let { statusCode } = response;

  try {
    if (statusCode === 200) {
      return {
        success: response.data.status,
        data: response.data.data,
        message: response?.data?.message|| "Someting went wrong, please try again...",
        status_code: response?.data?.status_code || 200,
        otp_sent: response?.data?.otp_sent || false,
        // status:response?.data?.status
      };
    } else if (statusCode === 500) {
      return { success: false, message: "Someting went wrong" };
    }
    return {
      success: response.response.data.status,
      message: response?.response?.data?.message|| "Someting went wrong, check your internet....",
    };
  } catch (error) {
    console.log(error)


    return {

      error: error?.response?.response?.data?.status || "Someting went wrong, check your internet connection.....",
      message: error?.response?.response?.data?.message || "Someting went wrong, check your internet connection......",
    };
  }
}



export async function register(data) {
  const result = await instance.post("/register", data);
  return processResult(result);
}


export async function validateRegistration(data) {
  const result = await instance.post("/validateReg", data);
  return processResult(result);
}


export async function login(data) {
  const result = await instance.post("/login", data);
  return processResult(result);
}


export async function acceptTerms(data) {
  const result = await instance.post("/submit_tc", data);
  return processResult(result);
}




export async function subscribeToPlan(data) {
  const result = await instance.post("/subscribe", data);
  return processResult(result);
}

export async function fetchGame(msisdn) {
  const result = await instance.get(`/fetch_questions?msisdn=${msisdn}`);
  return processEncryResult(result);
}


// export async function fetchGame(msisdn) {
//   const result = await instance.get(`/fetch_questions_encrypt?msisdn=${msisdn}`);
//   // return processResult(result);
//   return processEncryResult(result);

  
// }


export async function fetchGameOnly(msisdn) {
  const result = await instance.get(`/fetch_questions_only?msisdn=${msisdn}`);
  // return processResult(result);
  return processEncryResult(result);
}



export async function submitGame(data) {
  // const result = await instance.post("/submit_question", encryptJson({data:data}));
  let encData = {
    message: 'Successful',
    status: true,
    data: encryptJson(data)
  }
  const result = await instance.post("/submit_question_encrypt", encData);
  return processResult(result);
}


// export async function submitGame(data) {
//   const result = await instance.post("/submit_question_encrypt", encryptJson({data:data}));
//   return processResult(result);
// }


export async function getMoreQuestions(data) {
  const result = await instance.post(`/more_question?network=${REACT_NETWORK_PROVIDER.toUpperCase()}`, data);
  return processEncryResult(result);
  // return processResult(result);
}



export async function generateOtp(data) {
  const result = await instance.post("/otp", data);
  return processResult(result);
}

export async function validateOTP(data) {
  const result = await instance.post("/validateOTP", data);
  return processResult(result);
}


export async function fetchPlans() {
  const result = await instance.get(`/fetch_plans?network=${REACT_NETWORK_PROVIDER.toUpperCase()}`);
  return processResult(result);
}

export async function fetchUserGames(msisdn, status) {
  const result = await instance.get(`/fetch_user_games?msisdn=${msisdn}&status=${status}`);
  return processResult(result);
}



export async function fetchUserProfile(msisdn) {
  const result = await instance.get(`/profile?msisdn=${msisdn}`);
  return processResult(result);
}


export async function updateProfile(data) {
  const result = await instance.post("/updateProfile", data);
  return processResult(result);
}


export async function fetchLeaderboard(month) {
  const result = await instance.get(`/leaderboard?month=${month}`);
  return processResult(result);
}

export async function randomWins() {
  const result = await instance.get("/dashboard");
  return processResult(result);
}



export async function submitBankInfo(data) {
  const result = await instance.post("/submitBankInfo", data);
  return processResult(result);
}


export async function getBankInfo(msisdn) {
  const result = await instance.get(`/fetch_bank?msisdn=${msisdn}`);
  return processResult(result);
}





export default {
  register,
  validateRegistration,
  login,
  subscribeToPlan,
  fetchGame,
  submitGame,
  getMoreQuestions,
  generateOtp,
  validateOTP,
  fetchPlans,
  fetchUserGames,
  fetchGameOnly,
  fetchUserProfile,
  updateProfile,
  fetchLeaderboard,
  randomWins,
  acceptTerms,
  submitBankInfo,
  getBankInfo,
};

