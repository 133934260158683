export const FORM_FIELD_WIDTH = '40px';
export const FORM_FIELD_BORDER_RADIUS = '0.5rem';
export const YELLOW_COLOR_DEFAULT = "#FFB61D";
export const PURPLE_COLOR_DEFAULT = "#F49D1A";

export const MTN_COLOR_DEFAULT = "#FFB61D";
export const AIRTEL_COLOR_DEFAULT = "#FF0000";
export const GLO_COLOR_DEFAULT = "#00853f";

export const LIGHT_COLOR_MTN = "#fddc82";
export const LIGHT_COLOR_AIRTEL = "#FF474C";
export const LIGHT_COLOR_GLO = "#bbe4a7";

// const networkProvider = process.env.REACT_APP_NETWORK_PROVIDER;

export const REACT_NETWORK_PROVIDER = process.env.REACT_APP_NETWORK_PROVIDER //"airtel";
export const DEFAULT_COLOR = '#993366';
export function getColorStyle() {
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return { color: GLO_COLOR_DEFAULT };
      case 'mtn':
        return { color: MTN_COLOR_DEFAULT };
      case 'airtel':
        return { color: AIRTEL_COLOR_DEFAULT };
      default:
        return { color: DEFAULT_COLOR };
    }
  }

  export function getLightColor() {
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return { color: LIGHT_COLOR_GLO };
      case 'mtn':
        return { color: LIGHT_COLOR_MTN };
      case 'airtel':
        return { color: LIGHT_COLOR_AIRTEL };
      default:
        return { color: DEFAULT_COLOR };
    }
  }

  export function getWhiteColorClass() {
    return 'network_white_color'
  }

  export function getglowButton() {
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return 'glow-button-glo';
      case 'mtn':
        return "glow-button-mtn";
      case 'airtel':
        return "glow-button-airtel";
      default:
        return "default_color_dark";
    }
  }
  

  export function getDarkColorClass() {
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return 'glo_color_dark';
      case 'mtn':
        return "mtn_color_dark";
      case 'airtel':
        return "airtel_color_dark";
      default:
        return "default_color_dark";
    }
  }

  export function getLightColorClass(){
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return 'glo_color_light';
      case 'mtn':
        return "mtn_color_light";
      case 'airtel':
        return "airtel_color_light";
      default:
        return "default_color_dark";
    }
    
  }

  export function getBackgroundColorClass(){
    switch (REACT_NETWORK_PROVIDER) {
      case 'glo':
        return 'glo_color_background';
      case 'mtn':
        return "mtn_color_background";
      case 'airtel':
        return "airtel_color_background";
      default:
        return "default_color_dark";
    }
    
  }


export const BANKS = [
    { "id": "1", "name": "Access Bank" ,"code":"044" },
    { "id": "2", "name": "Citibank","code":"023" },
    { "id": "3", "name": "Diamond Bank","code":"063" },
    { "id": "4", "name": "Dynamic Standard Bank","code":"" },
    { "id": "5", "name": "Ecobank Nigeria","code":"050" },
    { "id": "6", "name": "Fidelity Bank Nigeria","code":"070" },
    { "id": "7", "name": "First Bank of Nigeria","code":"011" },
    { "id": "8", "name": "First City Monument Bank","code":"214" },
    { "id": "9", "name": "Guaranty Trust Bank","code":"058" },
    { "id": "10", "name": "Heritage Bank Plc","code":"030" },
    { "id": "11", "name": "Jaiz Bank","code":"301" },
    { "id": "12", "name": "Keystone Bank Limited","code":"082" },
    { "id": "13", "name": "Providus Bank Plc","code":"101" },
    { "id": "14", "name": "Polaris Bank","code":"076" },
    { "id": "15", "name": "Stanbic IBTC Bank Nigeria Limited","code":"221" },
    { "id": "16", "name": "Standard Chartered Bank","code":"068" },
    { "id": "17", "name": "Sterling Bank","code":"232" },
    { "id": "18", "name": "Suntrust Bank Nigeria Limited","code":"100" },
    { "id": "19", "name": "Union Bank of Nigeria","code":"032" },
    { "id": "20", "name": "United Bank for Africa","code":"033" },
    { "id": "21", "name": "Unity Bank Plc","code":"215" },
    { "id": "22", "name": "Wema Bank","code":"035" },
    { "id": "23", "name": "Zenith Bank","code":"057" }
]