const keyWord = 'MAGIC_FINGERS';

export const getToken = () => {
    return localStorage.getItem(keyWord+"_TOKEN");
}

export const setToken = (token) => {
     localStorage.setItem(keyWord+"_TOKEN", token);
}


export const setExpiresAt = (expiresAt) => {
    localStorage.setItem(keyWord+"_EXPIRES_AT", expiresAt);
}


export const setATC = (status) => {
    localStorage.setItem(keyWord+"_ACCEPT_T_C", status);
}


export const clearStorage = () => {
    localStorage.clear();
}
