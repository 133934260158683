import React, {createContext, useContext, useState} from 'react';
import { removeToken } from "../helpers/localStorage";


const AuthContext = createContext ({});

const { Provider } = AuthContext;

  

const AuthProvider =({ children }) =>{
    const token = localStorage.getItem('MAGIC_FINGERS');
    const expiresAt = localStorage.getItem('MAGIC_FINGERS_EXPIRES_AT');
    const acceptTC = localStorage.getItem('MAGIC_FINGERS_ACCEPT_T_C');    

    var retrievedPerson = JSON.parse(localStorage.getItem('MAGIC_FINGERS_USER_DATA'));

    const monthly_score = retrievedPerson?.monthly_score
    const msisdn = retrievedPerson?.msisdn
    const today_score = retrievedPerson?.today_score
    const uid = retrievedPerson?.uid
    const weekly_score = retrievedPerson?.weekly_score
    const user_token = retrievedPerson?.token
    const plan = retrievedPerson?.plan
    const subscription_status = retrievedPerson?.status
    const auto_renew = retrievedPerson?.auto_renew
    // const acceptTC = retrievedPerson?.tnc






    const [authState, setAuthState] = useState({
        token,
        monthly_score,
        msisdn,
        today_score,
        uid,
        weekly_score,
        user_token,
        expiresAt,
        plan,
        subscription_status,
        auto_renew,
        acceptTC

    })
    const setAuthInfo = ({token, monthly_score, msisdn, today_score, uid, weekly_score, user_token,expiresAt, plan, subscription_status, auto_renew, acceptTC}) =>{


        setAuthState({
          token,
          monthly_score,
          msisdn,
          today_score,
          uid,
          weekly_score,
          user_token,
          expiresAt,
          plan,
          subscription_status, 
          auto_renew,
          acceptTC

        })

    }
 

    const isAuthenticated = () => {
        var currentTime = new Date();
        // var timeOfExpiryString =  localStorage.getItem('AGGREGATOR_CMS_EXPIRES_AT')
        var timeOfExpiryObject = new Date(localStorage.getItem('MAGIC_FINGERS_EXPIRES_AT'))

        if (!authState.user_token || !timeOfExpiryObject) {
          // console.log(1)
            return false;
        }
        if(currentTime > timeOfExpiryObject ){
          // console.log(2)
          return false;
        }

      return true
    };
   

    const userData = () =>{
      return authState
    }

    const isAdmin = () => {
      return authState.role === 'ADMIN';
    };

    const isReseller = () => {
        return authState.role === 'RESELLER';
      };

    const isBussiness = () => {
        return authState.role === 'BUSINESS';
      };

      const isNormal = () => {
        return authState.role === 'NORMAL';
      };

      const isUndefined = () => {
        return authState.role === '';
      };

      const tearmsandConditions = () => {
        
        const acceptTC = authState.acceptTC === '0' ? false : true
        
        return acceptTC
      };


      
    return(
        <Provider value={{
            authState,
            setAuthState: (authInfo) => setAuthInfo(authInfo),
            isAuthenticated,
isAdmin,
isReseller,
isNormal,
isBussiness,
userData,
tearmsandConditions
        }}>
            {children}
        </Provider>
    )
}

export {AuthContext, AuthProvider}